import { Dropdown, Icon, Image, Label, Menu } from "semantic-ui-react";
import React, { useContext } from "react";
import { bulkLeSearchClients, termsServiceURL } from "../services/Constants";

import { AppContext } from "./AppContext";
import { Link } from "react-router-dom";
import config from "../config";
import fastLogo from "../images/fasano-logo.png";
import lexservLogo from "../images/lexserv_logo.png";
import twentyfirstLogo from "../images/twentyfirst_logo.png";
import { useAuth0 } from "@auth0/auth0-react";

const Navbar: React.FC = () => {
    const { isAuthenticated, logout, user } = useAuth0();
    const { unreadMessageCount, userClients, permissions } = useContext(AppContext);

    const canPerformBulkLESearch = !!userClients.find(
        (client) => client.ClientID === bulkLeSearchClients.coventry || client.ClientID === bulkLeSearchClients.itmSample
    );

    return (
        <>
            <Menu>
                <Menu.Menu position="left">
                    <Menu.Item
                        as={Link}
                        to="/dashboard"
                        header
                        content={<Image style={{ width: "159px" }} src={twentyfirstLogo} alt="21st LE" />}
                        position="left"
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                    />
                    {isAuthenticated && (
                        <>
                            <Dropdown item text="Life Expectancies">
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/le-search">
                                        <Icon name="search" color="blue" />
                                        Search
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/le-order">
                                        <Icon name="certificate" color="blue" />
                                        Order Life Expectancy
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {permissions.ConnectLESearch && (
                                <Dropdown item text="Data & Analytics">
                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="/le-verify">
                                            <Icon name="check" color="blue" />
                                            LE Verification
                                        </Dropdown.Item>
                                        {canPerformBulkLESearch && (
                                            <Dropdown.Item as={Link} to="/bulk-le-search">
                                                <Icon name="search plus" color="blue" />
                                                Bulk LE Search
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}

                            <Dropdown item text="Other Services">
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <Image src={lexservLogo} href={config.lexservWebUrl} target="_blank" />
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Image src={fastLogo} href={config.fastWebUrl} target="_blank" />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    )}
                </Menu.Menu>
                <Menu.Menu position="right">
                    <Menu.Item as={Link} to="/inbox">
                        <Icon name="mail" color="blue" />
                        Inbox
                        <Label circular color="blue" size="small">
                            {unreadMessageCount}
                        </Label>
                    </Menu.Item>
                    <Menu.Item href={termsServiceURL} target="_blank">
                        <Icon name="file alternate outline" className="itm-blue" />
                        Terms of Service
                    </Menu.Item>
                    <Dropdown item text={user?.email}>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/my-account">
                                <Icon name="user" color="blue" />
                                My Account
                            </Dropdown.Item>
                            {permissions.Admin && (
                                <Dropdown.Item as={Link} to="/user-management">
                                    <Icon name="users" color="blue" />
                                    User Management
                                </Dropdown.Item>
                            )}
                            <Dropdown.Item onClick={() => logout()}>
                                <Icon name="log out" color="blue" />
                                Log Out
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
            </Menu>
        </>
    );
};

export default Navbar;
