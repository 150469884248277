/* eslint-disable @typescript-eslint/no-var-requires */

const env = process.env.REACT_APP_ENVIRONMENT || "dev";
const rootConfig = require("./config.json");
const envConfig = require(`./config.${env}`);

const config: {
    auth0: {
        audience: string;
        clientId: string;
        domain: string;
    };
    authApiUrl: string;
    cloudApiBaseUrl: string;
    databrokerAPIURL: string;
    fileApiUrl: string;
    paymentApiUrl: string;
    supportEmailAddress: string;
    supportPhoneNumber: string;
    lexservWebUrl: string;
    fastWebUrl: string;
} = { ...rootConfig, ...envConfig };

export default config;
