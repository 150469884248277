import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import "./css/kendo.scss";
import "./css/App.scss";

import { Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense, lazy, useContext } from "react";

import { AppContext } from "./components/AppContext";
import AuthError from "./components/AuthError";
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import SimplePlaceholder from "./components/SimplePlaceholder";
import { useAuth0 } from "@auth0/auth0-react";

const BulkLESearch = lazy(() => import("./features/BulkLESearch/BulkLESearch"));
const Dashboard = lazy(() => import("./features/Dashboard/Dashboard"));
const Inbox = lazy(() => import("./features/Inbox/InboxPage"));
const LESearch = lazy(() => import("./features/LESearch/LESearch"));
const LEOrder = lazy(() => import("./features/LEOrder/LEOrder"));
const LEVerify = lazy(() => import("./features/LEVerify/LEVerify"));
const MyAccount = lazy(() => import("./features/MyAccount/MyAccount"));
const UploadAdditionalFiles = lazy(() => import("./features/LEOrder/UploadAdditionalFiles"));
const UserManagement = lazy(() => import("./features/UserManagement/UserManagement"));

const App: React.FC = () => {
    const { error, isLoading } = useAuth0();
    const { permissions } = useContext(AppContext);

    if (isLoading) {
        return <SimplePlaceholder />;
    }

    if (error) {
        return <AuthError error={error} />;
    }

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Routes>
                <Route path="/" element={<PrivateRoute component={Layout} />}>
                    <Route path="bulk-le-search" element={<BulkLESearch />} />
                    <Route path="inbox" element={<Inbox />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="le-files/:SubmitID" element={<UploadAdditionalFiles />} />
                    <Route path="le-search" element={<LESearch />} />
                    <Route path="le-order">
                        <Route path=":wsSubmitID" element={<LEOrder />} />
                        <Route path="" element={<LEOrder />} />
                    </Route>
                    <Route path="le-verify" element={permissions.ConnectLESearch ? <LEVerify /> : <></>} />
                    <Route path="my-account" element={<MyAccount />} />
                    <Route path="user-management" element={<PrivateRoute component={UserManagement} AllowIfAdmin />} />

                    <Route path="/" element={<Navigate to="/dashboard" replace />} />
                    <Route path="*" element={<Navigate to="/dashboard" replace />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default App;
