import { Client, UserPermissions } from "../types";
import { createContext, useCallback, useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import useSproc from "../hooks/useSproc";

export interface AppContextProps {
    unreadMessageCount: number;
    getUnreadMessageCount: () => void;
    permissions: UserPermissions;
    userClients: Client[];
}

export const AppContext = createContext<AppContextProps>({
    permissions: { Admin: false, CanViewAllConditions: false, ConnectLESearch: false },
    unreadMessageCount: 0,
    getUnreadMessageCount: () => {
        throw new Error("not implemented");
    },
    userClients: []
});

interface AppContextProviderProps {
    children: React.ReactNode;
}
const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [userClients, setUserClients] = useState<Client[]>([]);
    const [permissions, setPermissions] = useState<UserPermissions>({ Admin: false, CanViewAllConditions: false, ConnectLESearch: false });

    const sproc = useSproc();
    const { isAuthenticated } = useAuth0();

    const getUnreadMessageCount = useCallback(async () => {
        try {
            if (isAuthenticated) {
                const [{ UnreadMessageCount }] = await sproc<{ UnreadMessageCount: number }>("GetClientUnreadMessageCount");
                setUnreadMessageCount(UnreadMessageCount);
            }
        } catch (err) {
            toast.error("Failed to get unread messages");
        }
    }, [isAuthenticated, sproc]);

    useEffect(() => {
        (async () => {
            await getUnreadMessageCount();
        })();
    }, [getUnreadMessageCount]);

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }
        (async () => {
            const [userClientData, [permissionData]] = await Promise.all([
                sproc<Client>("GetUserClients"),
                sproc<UserPermissions>("GetTwentyfirstLEUserPermissions")
            ]);
            setUserClients(userClientData);
            setPermissions(permissionData);
        })();
    }, [sproc, isAuthenticated]);

    return (
        <AppContext.Provider
            value={{
                unreadMessageCount,
                getUnreadMessageCount,
                userClients,
                permissions
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
